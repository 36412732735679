import { keyBy } from "lodash";
/**
 * Parses a remote collection and ensures that the same reference is used when an element in the server response matches
 * an element in the "oldElements" collection. The match is performed by comparing the non-null id of the server response model
 * and the model in the "oldElements" collection.
 *
 * @param serverResponse the server response, can be undefined
 * @param oldElements the old elements if any or undefined
 * @param ctor the constructor for creating a new model instance
 * @param args arguments that are passed to {@link IParsable.parse}.
 * @returns {T[]} the parsed collection, not undefined
 */
export function parseCollection(serverResponse, oldElements, ctor, ...args) {
  serverResponse = serverResponse || [];
  const existingById = keyBy(oldElements?.filter(e => e.id !== null) || [], element => element.id);
  const result = [];
  for (const elementResponse of serverResponse) {
    let element = existingById[elementResponse["id"]];
    if (!element) {
      element = new ctor();
    }
    element.parse.apply(element, [elementResponse].concat(args));
    result.push(element);
  }
  return result;
}

/*
 * Set of utility functions for working with NonNullable instances.
 */
/**
 * Asserts if the given instance is NonNullable:
 * @throws Error when the given instance is nullable
 * @example
 *      ngOnInit(): void {
 *         assertNonNull(this.credit);
 *         this.form.initFromModel(this.credit);
 *      }
 */
export function assertNonNull(instance) {
  if (!instance) {
    throw new Error("Object is null or undefined");
  }
}
/**
 * Returns the given instance only when NonNullable
 * @throws Error when the given instance is nullable
 * @example
 *      ngOnInit(): void {
 *         this.form.initFromModel(nonNull(this.credit));
 *      }
 */
export function nonNull(instance) {
  assertNonNull(instance);
  return instance;
}

/**
 * Class for working with money. It defines an value in a certain currency.
 * The class is designed as immutable from typescript point of view.
 */
export class Amount {
  value;
  currency;
  static chf(value) {
    return new Amount(value);
  }
  static usd(value) {
    return new Amount(value, "USD");
  }
  static eur(value) {
    return new Amount(value, "EUR");
  }
  constructor(value = null, currency = "CHF") {
    this.value = value;
    this.currency = currency;
  }
  /**
   * Returns a new amount with the given value and the same currency;
   * @param value the new value
   */
  ofValue(value) {
    return new Amount(value, this.currency);
  }
  parse(response) {
    Object.assign(this, response);
  }
}

import { ReplaySubject } from "rxjs";
import { take } from "rxjs/operators";
/**
 * A resource cache holds a list of resources that is available for sharing
 * and can be accessed by multiple subscribers simultaneously or sequentially based on a given key.
 *
 * The user of the resource cache is responsible for invalidating affected cache entries if resources have been
 * updated or deleted.
 */
export class ResourceCache {
  loadResource;
  isSameKey;
  cache = [];
  /**
   * Creates a new resource cache with a load method and compare keys functions
   *
   * @param loadResource - the load function for the resource values to be cached.
   * @param isSameKey - function for comparing the key when getting a cached resource value. Defaults to '==='.
   */
  constructor(loadResource, isSameKey = (a, b) => a === b) {
    this.loadResource = loadResource;
    this.isSameKey = isSameKey;
  }
  /**
   * Returns a cached value for a resource key (cache hit). If there is no value cached yet (cache miss),
   * then the given load function is used to load the missing value and populate the cache.
   *
   * Note: Concurrent get-calls for the same resource key will only call the load function once. The get-calls will
   * then return the same value once the load function returns its first value.
   */
  get(resourceKey) {
    let cachedResource = this.getCachedResourceByKey(resourceKey);
    if (!cachedResource) {
      const resource$ = new ReplaySubject(1);
      cachedResource = {
        resourceKey,
        resource$
      };
      this.cache.push(cachedResource);
      this.loadResource(resourceKey).pipe(take(1)).subscribe(response => resource$.next(response));
    }
    return cachedResource.resource$.asObservable().pipe(take(1));
  }
  getCachedResourceByKey(key) {
    return this.cache.find(cachedResource => this.isSameKey(cachedResource.resourceKey, key));
  }
  invalidateCachedResource(resourceKey) {
    this.cache = this.cache.filter(cachedResource => {
      if (this.isSameKey(cachedResource.resourceKey, resourceKey)) {
        cachedResource.resource$.complete();
        return false;
      }
      return true;
    });
  }
  clear() {
    this.cache.forEach(cachedResource => {
      cachedResource.resource$.complete();
    });
    this.cache = [];
  }
}

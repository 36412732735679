import moment from "moment";
export const LOCAL_DATE_FORMAT = "YYYY-MM-DD";
// allow parsing datetime without milliseconds portion, as those might be cut off in the json response (when value is 0ms)
const LOCAL_DATETIME_NO_MILLIS_FORMAT_PARSE = `${LOCAL_DATE_FORMAT}THH:mm:ss`;
// regular parsing format with exactly 3 numbers for millisecond portion
export const LOCAL_DATETIME_FORMAT = `${LOCAL_DATETIME_NO_MILLIS_FORMAT_PARSE}.SSS`;
// .SSSS format pattern for millisecond portion will parse ANY number of digits for the millisecond portion, but only consider the first 3 for the constructed moment instance
const LOCAL_DATETIME_FORMAT_PARSE = `${LOCAL_DATETIME_FORMAT}S`;
const DATE_PARSE_FORMATS = [LOCAL_DATE_FORMAT, LOCAL_DATETIME_FORMAT_PARSE, LOCAL_DATETIME_NO_MILLIS_FORMAT_PARSE];
/**
 * Converts an api relative path to the full api path
 * @param path the path that is relative to 'api'
 */
export function toApiPath(path) {
  let apiPath = "../api";
  if (path.startsWith("/")) {
    path = path.substr(1);
  }
  if (!apiPath.endsWith("/")) {
    apiPath += "/";
  }
  return `${apiPath}${path}`;
}
/**
 * Creates the json representation of a server side LocalDate (without time!).
 * @param date the date to format as json
 * @returns {any} the string representation of the date
 */
export function toApiDate(date) {
  if (date === undefined) {
    return undefined;
  } else if (date === null) {
    return null;
  }
  return moment(date).format(LOCAL_DATE_FORMAT);
}
/**
 * Creates the json representation of a server side LocalDate with time.
 * @param date the date to format as json
 * @returns {any} the string representation of the datetime
 */
export function toApiDateTime(date) {
  if (date === undefined) {
    return undefined;
  } else if (date === null) {
    return null;
  }
  return moment(date).format(LOCAL_DATETIME_FORMAT);
}
/**
 * Parses a LocalDate from a json response
 * @param date the date to parse
 * @returns {any} the parsed date or undefined if the value was undefined
 */
export function fromApiDate(date) {
  if (date === undefined) {
    return undefined;
  } else if (date === null) {
    return null;
  }
  // try parsing date-only value (in strict mode) as local date
  const parsedDate = moment(date, DATE_PARSE_FORMATS, true);
  if (!parsedDate.isValid()) {
    throw Error(`Could not parse invalid date value '${date}'. Supported formats: ${DATE_PARSE_FORMATS.join(", ")}`);
  }
  return parsedDate.toDate();
}
/**
 * Get the self href URL from a backend json response
 * @param response
 * @returns {string} response._links.self.href
 */
export function selfUrl(response) {
  return response["_links"]["self"]["href"];
}
/**
 * Default implementation of a page that is capable of parsing the return result.
 */
export class Page {
  modelParse;
  /**
   * Creates a new page for a list of objects that are defined as interfaces. Interfaces do not require any parsing.
   * @param resourceName the name of the resource inside the response, e.g. 'ads:customers'
   * @param response the server response object that contains the _embedded and page elements
   * @returns the page for this resource or undefined if the response was undefined
   */
  static forInterface(resourceName, response) {
    if (!response) {
      return undefined;
    }
    const parse = response => response;
    const page = new Page(resourceName, parse);
    page.parse(response);
    return page;
  }
  /**
   * Creates a new page for a list of objects that are implemented as classes. The response of the server will
   * be parsed using the {@link IParsable#parse}.
   * @param resourceName the name of the resource inside the response, e.g. 'ads:customers'
   * @param modelCtor the class that represents a single model / object
   * @param response the server response
   * @returns the page for this resource or undefined if the response was undefined.
   */
  static forClass(resourceName, modelCtor, response) {
    if (!response) {
      return undefined;
    }
    const modelParse = response => {
      const model = new modelCtor();
      model.parse(response);
      return model;
    };
    const page = new Page(resourceName, modelParse);
    page.parse(response);
    return page;
  }
  resourceName;
  totalPages = 0;
  totalElements = 0;
  pageSize = 0;
  number = 0;
  elements = [];
  constructor(resourceName, modelParse) {
    this.modelParse = modelParse;
    this.resourceName = resourceName;
  }
  parse(response) {
    const page = response["page"];
    this.totalElements = page["totalElements"];
    this.totalPages = page["totalPages"];
    this.number = page["number"];
    this.pageSize = page["size"];
    const embedded = response["_embedded"] || {};
    const elements = embedded[this.resourceName] || [];
    this.elements = elements.map(this.modelParse);
  }
}
